import {Box} from '@mui/material'
import React from 'react'

import {useStyles} from './InputErrorMessage.styles'

import {Typography} from '@hconnect/uikit'

type ErrorMessageProps = {
  errorMessage: string
}
export const InputErrorMessage = ({errorMessage}: ErrorMessageProps) => {
  const {classes} = useStyles()
  return (
    <Box className={classes.inputErrorMessageContainer} data-test-id="input-error-message">
      <Typography style={{color: '#00274D', fontSize: '16px'}}>{errorMessage}</Typography>
    </Box>
  )
}
