import {Alert, Link, Stack} from '@mui/material'
import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'

import {LegalContext} from '../context/LegalContext'
import {dataLinkRoutes} from '../routes'

export const DataProtectionPolicyVerificationAlert: React.FC = () => {
  const {t} = useTranslation()
  const legalData = useContext(LegalContext)

  const handleDataProtectionNavigationClick = () => {
    legalData.acknowledgeUserDataProtectionPrivacy()
  }

  const dataProtectionPrivacyPageOpened = () => {
    return window.location.href.includes(dataLinkRoutes.DataProtectionPage)
  }

  if (
    legalData.userAcknowledgedDataProtectionPrivacy !== false ||
    dataProtectionPrivacyPageOpened()
  ) {
    return <></>
  }

  return (
    <Stack spacing={2}>
      <Alert
        severity="info"
        sx={{borderRadius: '0px', justifyContent: 'center', alignItems: 'center'}}
        data-test-id="dataProtectionPolicyVerificationAlert-banner"
      >
        {t('legal.dataProtectionAlertMessage')}
        <Link
          href={dataLinkRoutes.DataProtectionPage}
          color="primary"
          target="_blank"
          onClick={handleDataProtectionNavigationClick}
          data-test-id="dataProtectionPolicyVerificationAlert-link"
        >
          {t('legal.dataProtectionAlertButtonLabel')}
        </Link>
      </Alert>
    </Stack>
  )
}
