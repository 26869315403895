import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  inputErrorMessageContainer: {
    backgroundColor: '#FFFFFF',
    borderRight: '1px solid #EAEDF0',
    borderLeft: '4px solid rgba(218, 9, 1, 1)',
    borderTop: '1px solid #EAEDF0',
    borderBottom: '1px solid #EAEDF0',
    borderRadius: '4px',
    padding: '12px 16px',
    lineHeight: '16px',
    marginTop: '14px',
    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.05)'
  }
}))
